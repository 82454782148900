import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: function () {
    return {
      categories: ['Standort', 'Artikeltyp', 'Artikelkategorie', 'Artikelgruppe', 'Status', 'Erlösgruppe'],
      filter: {
        filterCategory: '',
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: ''
      },
      years: []
    };
  },
  mounted() {
    this.filter.filterCategory = 'Standort';
    this.loadMinYear();
    this.updateFilter();
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date();
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year);
        }
      });
    },
    updateFilter() {
      axios.post('v1/statistics/revenue/filter/filtered', this.filter).then(() => {
        this.$emit('loadData');
      });
    },
    clearFilter() {
      this.filter = {
        filterCategory: 'Standort',
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: ''
      };
      this.updateFilter();
    }
  }
};