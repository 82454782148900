import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue';
export default {
  data: () => ({
    quickUpload: false,
    appointment: [],
    selectedAddress: null,
    showAddressPicturesDialog: false
  }),
  components: {
    AddressPicturesDialog
  },
  methods: {
    open(appointment) {
      this.appointment = appointment;
      this.quickUpload = true;
    },
    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false;
    }
  }
};