import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  data: () => ({
    appointmentHistory: false,
    history: [],
    dateTimeService
  }),
  components: {},
  methods: {
    open(history) {
      this.appointmentHistory = true;
      this.history = history;
    },
    loadDescriptionProperty(prop) {
      switch (prop) {
        case 'scope':
          return 'name';
        case 'state':
          return 'description';
        case 'location':
          return 'name';
        case 'type':
          return 'name';
      }
    },
    loadGermanPropertyName(prop) {
      switch (prop) {
        case 'scope':
          return 'Bereich';
        case 'state':
          return 'Status';
        case 'location':
          return 'Lokation';
        case 'type':
          return 'Terminart';
        case 'start':
          return 'Von';
        case 'end':
          return 'Bis';
        case 'allDay':
          return 'Ganztägig';
        case 'duration':
          return 'Dauer';
        case 'firstAddressKdSortname':
          return 'Erste Adresse';
        case 'telNr':
          return 'Tel.-Nr.';
        case 'title':
          return 'Betreff';
      }
    },
    loadValue(change, pos) {
      if (change[Object.keys(change)[0]][pos] != null) {
        if (Object.keys(change)[0] !== 'start' && Object.keys(change)[0] !== 'end' && Object.keys(change)[0] != 'duration' && Object.keys(change)[0] != 'allDay' && Object.keys(change)[0] != 'firstAddressKdSortname' && Object.keys(change)[0] != 'telNr' && Object.keys(change)[0] != 'title') {
          return change[Object.keys(change)[0]][pos][this.loadDescriptionProperty(Object.keys(change)[0])];
        } else if (Object.keys(change)[0] == 'start' || Object.keys(change)[0] == 'end') {
          return dateTimeService.getGermanDateFormat(change[Object.keys(change)[0]][pos]);
        } else if (Object.keys(change)[0] == 'duration') {
          var dur = dateTimeService.getDurationSplit(change[Object.keys(change)[0]][pos]);
          return `${dur.days} Tage ${dur.hours} Stunden ${dur.minutes} Minuten`;
        } else if (Object.keys(change)[0] == 'allDay') {
          return change[Object.keys(change)[0]][pos] == true ? 'ja' : 'nein';
        } else {
          return change[Object.keys(change)[0]][pos];
        }
      } else {
        return 'Kein Eintrag';
      }
    }
  }
};