import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, [_c(VCol, {
    attrs: {
      "md": "12",
      "lg": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('apexchart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    attrs: {
      "height": "500",
      "type": "bar",
      "options": _vm.options,
      "series": _vm.series
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };