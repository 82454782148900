import dateTimeService from '@/services/cal/dateTimeService.js';
import WarningDialog from '@/components/core/WarningDialog.vue';
export default {
  data: () => ({
    moveAppointmentModal: false,
    appointment: [],
    dateTimeService,
    currentDraggedEvent: []
  }),
  components: {
    WarningDialog
  },
  methods: {
    open(currentDraggedEvent, appointment) {
      if (currentDraggedEvent.start == appointment.start || currentDraggedEvent.end == appointment.end) {
        this.moveAppointmentModal = false;
        return false;
      }
      this.moveAppointmentModal = true;
      this.currentDraggedEvent = currentDraggedEvent;
      this.appointment = appointment;
    },
    save(isSerial = false, userChose = false) {
      console.info('🎉 save');
      if (this.appointment.serialNumber != 0 && userChose == false) {
        var actions = [{
          action: 'single',
          text: 'Einzeltermin bearbeiten',
          icon: 'mdi-calendar'
        }, {
          action: 'serial',
          text: 'Serientermin bearbeiten',
          icon: 'mdi-calendar-multiple'
        }];
        this.$refs.warning.open('Dieser Termin ist Teil einer Terminserie', actions);
      } else this.$emit('save', this.appointment, isSerial);
    },
    warningActionPerformed(action) {
      var isSerial = action === 'serial' ? true : false;
      this.save(isSerial, true, true);
    },
    undo() {
      console.info('🎉 undo');
      this.$emit('undo', this.currentDraggedEvent);
    }
  }
};