import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: () => ({
    filter: {
      startYear: '',
      endYear: '',
      formGroups: []
    },
    years: [],
    formGroups: []
  }),
  mounted() {
    this.loadMinYear();
    this.loadFilterData();
    this.updateFilter();
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date();
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year);
        }
      });
    },
    loadFilterData() {
      axios.get('v1/statistics/filter/formGroups').then(data => {
        this.formGroups = data.data;
      });
    },
    updateFilter() {
      axios.post('v1/statistics/keyfigures/filter/document', this.filter).then(() => {
        this.$emit('loadData');
      });
    },
    clearFilter() {
      this.filter = {
        startYear: '',
        endYear: '',
        formGroups: []
      };
      this.updateFilter();
    }
  }
};