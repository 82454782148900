import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: function () {
    return {
      years: [],
      months: ['Jan.', 'Feb.', 'März', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Okt', 'Nov.', 'Dez.'],
      locations: [],
      states: [],
      articleTypes: [],
      articleCategories: [],
      articleGroups: [],
      filter: {
        startYear: '',
        endYear: '',
        startMonth: '',
        endMonth: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      }
    };
  },
  mounted() {
    this.loadMinYear();
    this.updateFilter();
    this.loadFilterData();
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date();
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year);
        }
      });
    },
    loadFilterData() {
      axios.get('v1/statistics/filter/locations').then(data => {
        this.locations = data.data;
      });
      axios.get('v1/statistics/filter/states').then(data => {
        this.states = data.data;
      });
      var articleWrapper = {
        articleCategories: [],
        articleTypes: [],
        articleGroups: []
      };
      axios.post('v1/statistics/filter/articleTypes', articleWrapper).then(data => {
        this.articleTypes = data.data;
      });
      axios.post('v1/statistics/filter/articleCategories', articleWrapper).then(data => {
        this.articleCategories = data.data;
      });
      axios.post('v1/statistics/filter/articleGroups', articleWrapper).then(data => {
        this.articleGroups = data.data;
      });
    },
    updateFilter() {
      axios.post('v1/statistics/revenue/filter/comparison', this.filter).then(() => {
        this.$emit('loadData');
      });
    },
    clearFilter() {
      this.filter = {
        startYear: '',
        endYear: '',
        toCurrentMonth: '',
        wj: '',
        db: '',
        locations: [],
        states: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: []
      };
      this.updateFilter();
    },
    articleTypesChanged() {
      var articleWrapper = {
        articleCategories: [],
        articleTypes: this.filter.articleTypes,
        articleGroups: []
      };
      axios.post('v1/statistics/revenue/filter/articleCategories', articleWrapper).then(data => {
        this.articleCategories = data.data;
      });
    },
    articleCategoriesChanged() {
      var articleWrapper = {
        articleCategories: this.articleCategories,
        articleTypes: [],
        articleGroups: []
      };
      axios.post('v1/statistics/revenue/filter/articleGroups', articleWrapper).then(data => {
        this.articleGroups = data.data;
      });
    }
  }
};