import Loading from '@/components/calendar_v2/Loading.vue';
import { mapState } from 'vuex';
import dateTimeService from '@/services/cal/dateTimeService.js';
import YouSure from '@/components/calendar_v2/YouSure.vue';
export default {
  computed: {
    ...mapState({
      foundAppointments: state => state.calAppointments.foundAppointments
    })
  },
  data: () => ({
    appointmentSearch: false,
    searchText: '',
    appointments: [],
    headers: [{
      text: 'Betreff',
      value: 'title',
      align: 'start'
    }, {
      text: 'Von',
      value: 'startFormatted',
      align: 'start'
    }, {
      text: 'Bis',
      value: 'endFormatted',
      align: 'start'
    }, {
      text: 'Ganztägig',
      value: 'allDayFormatted',
      align: 'center'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12rem'
    }],
    toDelete: []
  }),
  components: {
    Loading,
    YouSure
  },
  methods: {
    open() {
      this.appointments = [];
      this.searchText = '';
      this.appointmentSearch = true;
    },
    searchAppointments() {
      this.$refs.loading.open('Termine werden geladen ...');
      this.$store.dispatch('searchAppointments', this.searchText).then(() => {
        this.appointments = this.foundAppointments;
        this.appointments.forEach(element => {
          element.startFormatted = dateTimeService.getGermanDateFormat(element.start);
          element.endFormatted = dateTimeService.getGermanDateFormat(element.end);
          if (element.allDay) element.allDayFormatted = 'Ja';else element.allDayFormatted = 'Nein';
        });
        this.$refs.loading.close();
      });
    },
    setToDate(appointment) {
      this.$emit('setToDate', appointment.start);
      this.appointmentSearch = false;
    },
    editAppointment(appointment) {
      this.appointmentSearch = false;
      this.$emit('editAppointment', appointment);
    },
    tryDeleteAppointment(appointment) {
      this.toDelete = appointment;
      this.$refs.sureToDelete.open('Wollen Sie den Termin löschen?', 'LÖSCHEN');
    },
    deleteAppointment() {
      this.$emit('deleteAppointment', this.toDelete);
      this.appointments.splice(this.appointments.indexOf(this.toDelete), 1);
    }
  }
};