import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "customer"
  }, [_vm._v("Kunden")]), _c(VTab, {
    key: "distributor"
  }, [_vm._v("Lieferanten")]), _c(VTab, {
    key: "documents"
  }, [_vm._v("Dokumente")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "customer",
    staticClass: "mt-6 mb-4 mx-4"
  }, [_c('CustomerFilter', {
    on: {
      "loadData": _vm.loadDataCustomer
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerCount', {
    ref: "customerCount"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerNew', {
    ref: "customerNew"
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerRevenue', {
    ref: "customerRevenue"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerDB', {
    ref: "customerDB"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerTop', {
    ref: "customerTop",
    on: {
      "openCustomerGrouped": _vm.openCustomerGrouped
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerGrouped', {
    ref: "customerGrouped",
    on: {
      "openCustomerArticles": _vm.openCustomerArticles
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerArticles', {
    ref: "customerArticles",
    on: {
      "openCustomerDocuments": _vm.openCustomerDocuments
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('CustomerDocuments', {
    ref: "customerDocuments"
  })], 1)], 1)], 1), _c(VTabItem, {
    key: "distributor",
    staticClass: "mt-6 mx-4 mb-4"
  }, [_c('DistributorFilter', {
    on: {
      "loadData": _vm.loadDataDistributor
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorCount', {
    ref: "distributorCount"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorRevenue', {
    ref: "distributorRevenue"
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorComparison', {
    ref: "distributorComparison"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorComparisonAmount', {
    ref: "distributorComparisonAmount"
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorTop', {
    ref: "distributorTop",
    on: {
      "openDistributorGrouped": _vm.openDistributorGrouped
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorGrouped', {
    ref: "distributorGrouped",
    on: {
      "openDistributorArticles": _vm.openDistributorArticles
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorArticles', {
    ref: "distributorArticles",
    on: {
      "openDistributorDocuments": _vm.openDistributorDocuments
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DistributorDocuments', {
    ref: "distributorDocuments"
  })], 1)], 1)], 1), _c(VTabItem, {
    key: "documents",
    staticClass: "mt-6 mx-4 mb-4"
  }, [_c('DocumentFilter', {
    on: {
      "loadData": _vm.loadDataDocument
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DocumentCount', {
    ref: "documentCount",
    on: {
      "openDocumentRevenue": _vm.openDocumentRevenue
    }
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DocumentRevenue', {
    ref: "documentRevenue"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('DocumentDB', {
    ref: "documentDB"
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };