import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import axios from 'axios';
export default {
  data: () => ({
    show: false,
    headers: [{
      text: 'Nr.',
      value: 'key.artNr'
    }, {
      text: 'Bezeichnung',
      value: 'name'
    }, {
      text: 'Umsatz',
      value: 'revenue'
    }, {
      text: 'Deckungsbeitrag',
      value: 'db'
    }, {
      text: 'Menge',
      value: 'amount'
    }],
    items: [],
    currencyFormatService,
    search: '',
    kdSortname: '',
    grouped: ''
  }),
  methods: {
    open(data, kdSortname, grouped) {
      this.kdSortname = kdSortname;
      this.grouped = grouped;
      this.items = data;
      this.show = true;
    },
    clickedArticle(article) {
      axios.get('v1/statistics/keyfigures/distributor/documents', {
        params: {
          kdSortname: this.kdSortname,
          artNr: article.key.artNr
        }
      }).then(data => {
        this.$emit('openDistributorDocuments', data.data);
      });
    }
  }
};