import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import dateTimeService from '@/services/cal/dateTimeService';
export default {
  data: () => ({
    show: false,
    headers: [{
      text: 'Dokument',
      value: 'fart'
    }, {
      text: 'Datum',
      value: 'date'
    }, {
      text: 'Umsatz',
      value: 'revenue'
    }, {
      text: 'Deckungsbeitrag',
      value: 'db'
    }, {
      text: 'Menge',
      value: 'amount'
    }],
    items: [],
    currencyFormatService,
    search: '',
    dateTimeService
  }),
  methods: {
    open(data) {
      this.items = data;
      this.show = true;
    }
  }
};