import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mx-2"
  }, [_c(VSelect, {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Bestimmen nach...",
      "items": _vm.items
    },
    on: {
      "change": _vm.loadData
    },
    model: {
      value: _vm.current,
      callback: function ($$v) {
        _vm.current = $$v;
      },
      expression: "current"
    }
  })], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "md": "12",
      "lg": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "500",
      "options": _vm.options,
      "series": _vm.series
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };