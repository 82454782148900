import TaskTable from '@/components/task/TaskTable.vue';
import { mapState } from 'vuex';
export default {
  data: () => ({
    tab: 'open',
    adminView: false
  }),
  components: {
    TaskTable
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      taskPreviews: state => state.task.taskPreviews,
      taskPreviews_open: state => state.task.taskPreviews_open,
      taskPreviews_done: state => state.task.taskPreviews_done,
      taskPreviewsUser: state => state.task.taskPreviewsUser,
      taskPreviewsUser_open: state => state.task.taskPreviewsUser_open,
      taskPreviewsUser_done: state => state.task.taskPreviewsUser_done
    }),
    adminViewLabel: function () {
      var returnString = 'Adminansicht: ';
      if (this.adminView) returnString = returnString + 'aktiviert';else returnString = returnString + 'deaktiviert';
      return returnString;
    },
    isAdmin() {
      return this.user.userLvl >= 250 ? true : false;
    }
  },
  mounted() {
    this.$store.dispatch('loadUserData').then(() => {
      this.$store.dispatch('loadTaskPreviewsForUser');
      if (this.user.userLvl >= 250) this.$store.dispatch('loadTaskPreviews');
    });
  }
};