import YearComparison from '@/components/bi/revenue/YearComparison.vue';
import MonthComparison from '../../components/bi/revenue/MonthComparison.vue';
import FilterComparison from '../../components/bi/revenue/FilterComparison.vue';
import YearMonthFilter from '../../components/bi/revenue/YearMonthFilter.vue';
import FilteredFilter from '../../components/bi/revenue/FilteredFilter.vue';
export default {
  components: {
    YearComparison,
    FilterComparison,
    YearMonthFilter,
    FilteredFilter,
    MonthComparison
  },
  methods: {
    loadDataYearMonth() {
      this.$refs.year.loadData();
      this.$refs.month.loadData();
    },
    loadFiltered() {
      this.$refs.filtered.loadData();
    }
  }
};