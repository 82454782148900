import store from '@/store'
import axios from 'axios'

export default {
  getValueFromName(name) {
    const foundValue = store.getters.settings_cal.find(
      s => s.valueName === name
    )
    if (foundValue !== undefined) {
      return foundValue.value
    } else {
      return null
    }
  },
  saveUserSettings(user, settings) {
    return axios.put(`v1/settings/user/${user}`, settings)
  }
}
