import CustomerCount from '../../components/bi/keyfigures/CustomerCount.vue';
import CustomerRevenue from '../../components/bi/keyfigures/CustomerRevenue.vue';
import CustomerTop from '../../components/bi/keyfigures/CustomerTop.vue';
import DistributorCount from '../../components/bi/keyfigures/DistributorCount.vue';
import DistributorRevenue from '../../components/bi/keyfigures/DistributorRevenue.vue';
import DistributorTop from '../../components/bi/keyfigures/DistributorTop.vue';
import CustomerNew from '../../components/bi/keyfigures/CustomerNew.vue';
import CustomerFilter from '../../components/bi/keyfigures/CustomerFilter.vue';
import DistributorComparison from '../../components/bi/keyfigures/DistributorComparison.vue';
import DistributorFilter from '../../components/bi/keyfigures/DistributorFilter.vue';
import DistributorComparisonAmount from '../../components/bi/keyfigures/DistributorComparisonAmount.vue';
import DocumentFilter from '../../components/bi/keyfigures/DocumentFilter.vue';
import DocumentCount from '../../components/bi/keyfigures/DocumentCount.vue';
import DocumentRevenue from '../../components/bi/keyfigures/DocumentRevenue.vue';
import CustomerGrouped from '../../components/bi/keyfigures/CustomerGrouped.vue';
import CustomerArticles from '../../components/bi/keyfigures/CustomerArticles.vue';
import CustomerDocuments from '../../components/bi/keyfigures/CustomerDocuments.vue';
import DistributorGrouped from '../../components/bi/keyfigures/DistributorGrouped.vue';
import DistributorArticles from '../../components/bi/keyfigures/DistributorArticles.vue';
import DistributorDocuments from '../../components/bi/keyfigures/DistributorDocuments.vue';
import CustomerDB from '../../components/bi/keyfigures/CustomerDB.vue';
import DocumentDB from '../../components/bi/keyfigures/DocumentDB.vue';
export default {
  components: {
    CustomerCount,
    CustomerRevenue,
    CustomerTop,
    CustomerNew,
    DistributorCount,
    DistributorRevenue,
    DistributorTop,
    CustomerFilter,
    DistributorComparison,
    DistributorFilter,
    DistributorComparisonAmount,
    DocumentFilter,
    DocumentCount,
    DocumentRevenue,
    CustomerGrouped,
    CustomerArticles,
    CustomerDocuments,
    DistributorGrouped,
    DistributorArticles,
    DistributorDocuments,
    CustomerDB,
    DocumentDB
  },
  data: () => ({
    tab: ''
  }),
  methods: {
    loadDataCustomer() {
      this.$refs.customerCount.loadData();
      this.$refs.customerNew.loadData();
      this.$refs.customerRevenue.loadData();
      this.$refs.customerDB.loadData();
      this.$refs.customerTop.loadData();
      this.$refs.customerGrouped.show = false;
      this.$refs.customerArticles.show = false;
      this.$refs.customerDocuments.show = false;
    },
    loadDataDistributor() {
      this.$refs.distributorCount.loadData();
      this.$refs.distributorRevenue.loadData();
      this.$refs.distributorComparison.loadData();
      this.$refs.distributorTop.loadData();
      this.$refs.distributorComparisonAmount.loadData();
      this.$refs.distributorGrouped.show = false;
      this.$refs.distributorArticles.show = false;
      this.$refs.distributorDocuments.show = false;
    },
    loadDataDocument() {
      this.$refs.documentCount.loadData();
      this.$refs.documentRevenue.loadData();
      this.$refs.documentDB.loadData();
    },
    openDocumentRevenue(formGroup) {
      console.warn(formGroup);
      this.$refs.documentRevenue.loadData(formGroup, true);
      this.$refs.documentDB.loadData(formGroup, true);
    },
    openCustomerGrouped(data, kdSortname) {
      this.$refs.customerGrouped.open(data, kdSortname);
      this.$refs.customerArticles.show = false;
      this.$refs.customerDocuments.show = false;
    },
    openCustomerArticles(data, kdSortname, grouped) {
      this.$refs.customerArticles.open(data, kdSortname, grouped);
      this.$refs.customerDocuments.show = false;
    },
    openCustomerDocuments(data) {
      this.$refs.customerDocuments.open(data);
    },
    openDistributorGrouped(data, kdSortname) {
      this.$refs.distributorGrouped.open(data, kdSortname);
      this.$refs.distributorArticles.show = false;
      this.$refs.distributorDocuments.show = false;
    },
    openDistributorArticles(data, kdSortname, grouped) {
      this.$refs.distributorArticles.open(data, kdSortname, grouped);
      this.$refs.distributorDocuments.show = false;
    },
    openDistributorDocuments(data) {
      this.$refs.distributorDocuments.open(data);
    }
  }
};