/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DistributorGrouped.vue?vue&type=template&id=a3bc2cea"
import script from "./DistributorGrouped.vue?vue&type=script&lang=js"
export * from "./DistributorGrouped.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports