import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, [_c(VCol, {
    attrs: {
      "md": "12",
      "lg": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c(VDataTable, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "search": _vm.search,
      "items-per-page": "5"
    },
    on: {
      "click:row": _vm.clickedArticle
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_c(VTextField, {
          staticClass: "mt-8",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.key.artNr",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'Artikeldetails',
              params: {
                articleNumber: item.key.artNr
              }
            }
          }
        }, [_vm._v(_vm._s(item.key.artNr))])];
      }
    }, {
      key: "item.amount",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.amount + ' ' + item.amountType) + " ")];
      }
    }, {
      key: "item.revenue",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.currencyFormatService.formatEuro(item.revenue)) + " ")];
      }
    }, {
      key: "item.db",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.currencyFormatService.formatEuro(item.db)) + " ")];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };