import { mapState } from 'vuex';
export default {
  data: () => ({
    appointmentFilter: false,
    filter: {
      addresses: []
    },
    expansionPanelModel: [0],
    searchText: ''
  }),
  components: {},
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states
    })
  },
  methods: {
    open() {
      this.appointmentFilter = true;
    },
    clearFilter() {
      if (this.filter.scopes !== undefined) this.filter.scopes.splice(0);
      if (this.filter.types !== undefined) this.filter.types.splice(0);
      if (this.filter.states !== undefined) this.filter.states.splice(0);
      if (this.filter.locations !== undefined) this.filter.locations.splice(0);
      this.filter.isSet = false;
      this.$store.commit('SET_FILTER', this.filter);
      this.appointmentFilter = false;
    },
    applyFilter() {
      console.warn(this.filter);
      //TODO: Filter auf null setzen, wenn Filter hier leer
      this.filter.isSet = true;
      this.$store.commit('SET_FILTER', this.filter);
      this.appointmentFilter = false;
      this.$emit('applyFilter');
    }
  }
};