export default {
  toGermanName(value) {
    switch (value) {
      case 'from':
        return 'Von'
      case 'to':
        return 'Bis'
      case 'location':
        return 'Lokation'
      case 'scope':
        return 'Bereich'
      case 'type':
        return 'Terminart'
      case 'title':
        return 'Betreff'
      case 'address':
        return 'Erster Kundenname'
      case 'tel':
        return 'Tel.Nr.'
      case 'state':
        return 'Status'
    }
  },
  convertGermanNamesToDBString(values) {
    var returnString = ''
    values.forEach(element => {
      switch (element.name) {
        case 'Von':
          returnString = returnString + 'from;'
          break
        case 'Bis':
          returnString = returnString + 'to;'
          break
        case 'Lokation':
          returnString = returnString + 'location;'
          break
        case 'Bereich':
          returnString = returnString + 'scope;'
          break
        case 'Terminart':
          returnString = returnString + 'type;'
          break
        case 'Betreff':
          returnString = returnString + 'title;'
          break
        case 'Erster Kundenname':
          returnString = returnString + 'address;'
          break
        case 'Tel.Nr.':
          returnString = returnString + 'tel;'
          break
        case 'Status':
          returnString = returnString + 'state;'
          break
      }
    })
    return returnString
  },
  convertNamesToDBString(values) {
    var returnString = ''

    values.forEach(element => {
      returnString = returnString + element + ';'
    })

    return returnString
  }
}
