export default {
  data: () => ({
    warningDialog: false,
    warnMessage: '',
    actions: [],
    colsPerButton: 0,
    additionalData: []
  }),
  methods: {
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage;
      this.actions = actions;
      this.warningDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action);
      this.warningDialog = false;
    }
  }
};