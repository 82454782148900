import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mt-6 mx-4 mb-4"
  }, [_c('YearMonthFilter', {
    on: {
      "loadData": _vm.loadDataYearMonth
    }
  }), _c(VRow, [_c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('YearComparison', {
    ref: "year"
  })], 1), _c(VCol, {
    attrs: {
      "lg": "6",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('MonthComparison', {
    ref: "month"
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('FilteredFilter', {
    on: {
      "loadData": _vm.loadFiltered
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('FilterComparison', {
    ref: "filtered"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };