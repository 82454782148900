export default {
  data: () => ({
    youSure: false,
    promptText: '',
    actionOption: ''
  }),
  methods: {
    open(prompt, action) {
      this.promptText = prompt;
      this.actionOption = action;
      this.youSure = true;
    },
    actionPerformed() {
      this.$emit('actionPerformed');
      this.youSure = false;
    }
  }
};