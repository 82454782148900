import "core-js/modules/es.array.push.js";
import axios from 'axios';
export default {
  data: () => ({
    filter: {
      startYear: '',
      endYear: ''
    },
    years: []
  }),
  mounted() {
    this.loadMinYear();
    this.updateFilter();
  },
  methods: {
    loadMinYear() {
      axios.get('v1/statistics/revenue/minimum').then(data => {
        var today = new Date();
        for (let year = data.data; year <= today.getFullYear(); year++) {
          this.years.push(year);
        }
      });
    },
    updateFilter() {
      axios.post('v1/statistics/keyfigures/filter/customer', this.filter).then(() => {
        this.$emit('loadData');
      });
    },
    clearFilter() {
      this.filter = {
        startYear: '',
        endYear: ''
      };
      this.updateFilter();
    }
  }
};